import { Component, DestroyRef, inject, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-menu-icon-link',
  templateUrl: './menu-icon-link.component.html',
  styleUrls: ['./menu-icon-link.component.scss'],
})
export class MenuIconLinkComponent implements OnInit {
  @Input() link: string | string[] | undefined;
  @Input() tooltip: string | undefined;
  @Input() activePaths?: string[];

  private destroyRef = inject(DestroyRef);

  isActive = false;

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.isActive = this.isLinkActive();

    this.router.events.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.isActive = this.isLinkActive();
      }
    });
  }

  private isLinkActive() {
    const currentUrl = this.router.url;
    let link = this.link;

    if (Array.isArray(link)) {
      const urlTree = this.router.createUrlTree(link as any);
      link = this.router.serializeUrl(urlTree);
    }

    return !!(
      (link && currentUrl.includes(link)) ||
      (this.activePaths &&
        this.activePaths.filter(Boolean).some((route) => {
          if (Array.isArray(route)) {
            return route.some((link) => currentUrl.includes(link));
          }
          return currentUrl.includes(route);
        }))
    );
  }
}
