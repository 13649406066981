<ng-container *ngTemplateOutlet="!link ? buttonTpl : linkTpl"></ng-container>

<ng-template #linkTpl>
  <a
    class="cm-button cm-button-menu"
    [ngClass]="{ 'cm-button-active': isActive }"
    [routerLink]="link"
    [appTooltip]="tooltip"
    [tooltipVisibilityThreshold]="'lg'"
    [disabledOnMobile]="true"
  >
    <span class="shape">
      <svg-icon src="/assets/icons/40/bg-shape.svg" class="bg"></svg-icon>
      <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
    </span>
    <span class="icon-label">
      {{ tooltip }}
    </span>
  </a>
</ng-template>

<ng-template #buttonTpl>
  <button
    type="button"
    class="cm-button cm-button-menu"
    [ngClass]="{ 'cm-button-active': isActive }"
    [appTooltip]="tooltip"
    [tooltipVisibilityThreshold]="'lg'"
    [disabledOnMobile]="true"
  >
    <span class="shape">
      <svg-icon src="/assets/icons/40/bg-shape.svg" class="bg"></svg-icon>
      <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
    </span>
    <span class="lg:hidden menu-item-content">
      <ng-content select="[content]"></ng-content>
    </span>
  </button>
</ng-template>

<ng-template #contentTpl>
  <ng-content></ng-content>
</ng-template>
