<header
  [ngClass]="{
    basic: isBasic,
    secured: isSecured,
    'logged-in': (loggedIn$ | async),
    'not-logged-in': !(loggedIn$ | async),
    'trading-platform': isTradingPlatform,
    'quick-trade': isQuickTrade
  }"
>
  <div class="main">
    <div class="content">
      <!-- show basic menu w/ back btn -->
      <ng-container *ngIf="isBasic">
        <div class="w-1/3 flex justify-start">
          <a (click)="navigateBack()" class="cursor-pointer">
            <svg-icon
              class="inline-block"
              [svgStyle]="{ 'width.px': 22, 'height.px': 20 }"
              src="/assets/icons/arrow-left.svg"
            ></svg-icon>
          </a>
        </div>

        <div class="w-1/3 flex justify-center">
          <a class="logo" [routerLink]="'/' | localize">
            <app-theme-image
              srcLight="/assets/cm-logo.svg"
              srcDark="/assets/cm-logo-dark.svg"
              alt="Coinmate"
            ></app-theme-image>
          </a>
        </div>
        <div class="w-1/3 flex justify-end">
          <app-language-switch [type]="languageSwitchTypeEnum.menu"></app-language-switch>
        </div>
      </ng-container>

      <!-- public menu -->
      <ng-container *ngIf="!isSecured && !isBasic">
        <div class="navigation navigation-{{ currentLang }}" [ngClass]="{ open: !isClosed }">
          <div class="navigation-container">
            <div class="navigation-content navigation-content-gap-large">
              <a [routerLink]="'/' | localize" class="logo desktop-only">
                <app-theme-image
                  [srcLight]="(loggedIn$ | async) ? '/assets/cm-logo-short-secured.svg' : '/assets/cm-logo.svg'"
                  [srcDark]="
                    (loggedIn$ | async) ? '/assets/cm-logo-short-secured-dark.svg' : '/assets/cm-logo-dark.svg'
                  "
                  alt="Coinmate"
                ></app-theme-image>
              </a>
              <ul class="menu" *ngIf="appState !== 'verification'">
                <ng-container *ngFor="let link of menuLinks">
                  <li
                    *ngIf="((loggedIn$ | async) === true && !link.hideWhenLogged) || !(loggedIn$ | async)"
                    class="{{ link.czOnly ? 'cz-only' : '' }} {{ link.enOnly ? 'en-only' : '' }}"
                  >
                    <app-menu-link [link]="link.url ? (link.url | localize) : undefined" [children]="link.subMenu">{{
                      link.name | translate
                    }}</app-menu-link>
                  </li>
                </ng-container>
                <li *ngIf="(loggedIn$ | async) === true && appState !== 'verification'" class="desktop-only">
                  <app-menu-link [link]="'/quick-trade' | localize" icon="/assets/icons/icon-quick-trade.svg">
                    {{ 'shared.menu.buy-sell' | translate }}
                  </app-menu-link>
                </li>
              </ul>

              <!-- when not logged in -->
              <div *ngIf="!(loggedIn$ | async)" class="menu mobile-only">
                <a
                  [routerLink]="'/login' | localize"
                  class="cm-button cm-button-tertiary cm-button-small lg:cm-button-menu lg:cm-button-secondary-link"
                  >{{ 'shared.common.login' | translate }}</a
                >

                <ng-container *ngTemplateOutlet="publicSettings"></ng-container>
              </div>
            </div>

            <!-- when not logged in -->
            <div *ngIf="!(loggedIn$ | async)" class="navigation-content desktop-only">
              <a
                [routerLink]="'/login' | localize"
                class="cm-button cm-button-tertiary lg:cm-button-menu lg:cm-button-secondary-link"
                >{{ 'shared.common.login' | translate }}</a
              >
              <a [routerLink]="'/sign-up' | localize" class="cm-button cm-button-menu cm-button-primary">{{
                'shared.menu.signup' | translate
              }}</a>

              <ng-container *ngTemplateOutlet="publicSettings"></ng-container>
            </div>

            <ng-container *ngTemplateOutlet="userAccountLinks"></ng-container>
          </div>
        </div>
        <div class="mobile-only w-full flex flex-nowrap items-center justify-between">
          <a class="logo" [routerLink]="'/' | localize">
            <app-theme-image
              srcLight="/assets/cm-logo-mobile.svg"
              srcDark="/assets/cm-logo-mobile-dark.svg"
              alt="Coinmate"
            ></app-theme-image>
          </a>
          <div class="flex items-center gap-x-[16px]">
            <app-menu-link
              *ngIf="(loggedIn$ | async) === true && appState !== 'verification'"
              [hasOutline]="true"
              [link]="'/quick-trade' | localize"
              icon="/assets/icons/icon-quick-trade.svg"
            >
              {{ 'shared.menu.buy-sell' | translate }}
            </app-menu-link>
            <a
              *ngIf="!(loggedIn$ | async)"
              [routerLink]="'/sign-up' | localize"
              class="cm-button cm-button-menu cm-button-primary"
            >
              {{ 'shared.menu.signup' | translate }}
            </a>
            <ng-container *ngTemplateOutlet="burger"></ng-container>
          </div>
        </div>
      </ng-container>

      <!-- secured menu / always logged in -->
      <ng-container *ngIf="isSecured">
        <div class="navigation" [ngClass]="{ open: !isClosed }">
          <div class="navigation-container">
            <div class="navigation-content navigation-content-gap-large">
              <a class="logo desktop-only" [routerLink]="securedMenuLogoLink | localize">
                <app-theme-image
                  srcLight="/assets/cm-logo-short-secured.svg"
                  srcDark="/assets/cm-logo-short-secured-dark.svg"
                  alt="Coinmate"
                ></app-theme-image>
              </a>
              <ul class="menu" *ngIf="appState !== 'verification'">
                <li class="desktop-only">
                  <app-menu-link [link]="'/quick-trade' | localize" icon="/assets/icons/icon-quick-trade.svg">
                    {{ 'shared.menu.buy-sell' | translate }}
                  </app-menu-link>
                </li>
                <li>
                  <app-menu-link [link]="'/trade' | localize">{{ 'shared.menu.trade' | translate }}</app-menu-link>
                </li>
                <li>
                  <app-menu-link [link]="'/recurring-buy' | localize">{{
                    'shared.menu.recurring-buy' | translate
                  }}</app-menu-link>
                </li>
              </ul>
              <div *ngIf="appState !== 'verification'" class="menu mobile-only">
                <ng-container *ngIf="accountList.length >= 1">
                  <ng-container *ngTemplateOutlet="accountsList"></ng-container>
                </ng-container>
              </div>
            </div>

            <ng-container *ngTemplateOutlet="userAccountLinks"></ng-container>
          </div>
        </div>
        <div class="mobile-only w-full flex flex-nowrap items-center justify-between">
          <a class="logo" [routerLink]="securedMenuLogoLink | localize">
            <app-theme-image
              srcLight="/assets/cm-logo-mobile.svg"
              srcDark="/assets/cm-logo-mobile-dark.svg"
              alt="Coinmate"
            ></app-theme-image>
          </a>
          <div class="flex items-center gap-x-[16px]">
            <app-menu-link
              *ngIf="appState !== 'verification'"
              [hasOutline]="true"
              [link]="'/quick-trade' | localize"
              icon="/assets/icons/icon-quick-trade.svg"
            >
              {{ 'shared.menu.buy-sell' | translate }}
            </app-menu-link>
            <ng-container *ngTemplateOutlet="burger"></ng-container>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <ng-container *ngIf="isSecured && appState !== 'verification'">
    <div class="context-bar">
      <div class="content">
        <div *ngIf="isTradingPlatform" class="part lg:flex-1">
          <app-context-trade-currency-bar></app-context-trade-currency-bar>
        </div>
        <div *ngIf="isQuickTrade" class="part lg:flex-1">
          <app-context-currency-bar></app-context-currency-bar>
        </div>
        <div *ngIf="accountList.length >= 1" class="part account-change">
          <ng-container *ngTemplateOutlet="accountsList"></ng-container>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="!isSecured && isTradingPlatform">
    <div class="context-bar">
      <div class="content">
        <div class="part lg:flex-1">
          <app-context-trade-currency-bar></app-context-trade-currency-bar>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-template #accountsList>
    <ng-container *ngIf="!accountChangeDisabled; else accountChangeAlert">
      <app-menu-dropdown>
        <button [withChevronMobile]="true" class="text-large" menu-dropdown-trigger>
          <svg-icon src="/assets/icons/16/account-balance-wallet.svg" class="hidden lg:block"></svg-icon>
          <ng-container *ngTemplateOutlet="accountName"></ng-container>
        </button>
        <button *ngFor="let account of accountList" (click)="changeSelectedAccount(account.id)" menu-dropdown-item>
          {{ account.name | translate }}
        </button>
      </app-menu-dropdown>
    </ng-container>
  </ng-template>

  <ng-template #accountChangeAlert>
    <app-menu-dropdown [popoverClass]="'flex flex-col gap-y-12px lg:min-w-[348px] lg:gap-y-16px lg:items-start'">
      <button [withChevronMobile]="true" [inactive]="true" class="text-large" menu-dropdown-trigger>
        <svg-icon src="/assets/icons/16/account-balance-wallet.svg" class="hidden lg:block"></svg-icon>
        <ng-container *ngTemplateOutlet="accountName"></ng-container>
      </button>

      <div class="lg:text-large">
        <strong>{{ 'shared.menu.account-change-alert.title' | translate }}</strong>
      </div>
      <p>{{ 'shared.menu.account-change-alert.description' | translate }}</p>
      <button class="cm-button cm-button-primary cm-button-small">
        {{ 'shared.menu.account-change-alert.button' | translate }}
      </button>
    </app-menu-dropdown>
  </ng-template>

  <ng-template #accountName>
    {{ selectedAccount?.name ?? '' | translate }}
  </ng-template>

  <ng-template #userAccountLinks>
    <div *ngIf="(loggedIn$ | async) === true" class="navigation-content navigation-content-gap-small">
      <app-menu-link
        *ngIf="appState !== 'verification'"
        class="desktop-only"
        [link]="'/transfers' | localize"
        [activePaths]="transferActivePaths"
      >
        {{ 'shared.menu.deposit-withdrawal' | translate }}
      </app-menu-link>
      <ul class="menu menu-icons">
        <li *ngIf="appState !== 'verification'" class="mobile-only">
          <app-menu-icon-link
            [link]="'/transfers' | localize"
            [tooltip]="'shared.menu.deposit-withdrawal' | translate"
            [activePaths]="transferActivePaths"
          >
            <svg-icon svgClass="icon" src="/assets/icons/icon-transfers.svg"></svg-icon>
          </app-menu-icon-link>
        </li>
        <li *ngIf="appState !== 'verification'">
          <app-menu-icon-link [link]="'/portfolio' | localize" [tooltip]="'submenu.portfolio' | translate">
            <svg-icon svgClass="icon" src="/assets/icons/icon-portfolio.svg"></svg-icon>
          </app-menu-icon-link>
        </li>
        <li *ngIf="appState !== 'verification'">
          <app-menu-icon-link
            [link]="'/transaction-history' | localize"
            [activePaths]="historyActivePaths"
            [tooltip]="'shared.menu.history' | translate"
          >
            <svg-icon svgClass="icon" src="/assets/icons/icon-history.svg"></svg-icon>
          </app-menu-icon-link>
        </li>
        <li>
          <app-menu-icon-link [link]="'/customer-support' | localize" [tooltip]="'messages-notifications' | translate">
            <svg-icon svgClass="icon" src="/assets/icons/icon-life-ring.svg"></svg-icon>
          </app-menu-icon-link>
        </li>
        <li>
          <ng-container *ngIf="userMenuLinks.length; else simpleUserMenuLink">
            <app-menu-dropdown>
              <app-menu-icon-link
                [tooltip]="'user-account' | translate"
                [withChevronMobile]="true"
                [activePaths]="profileActivePaths"
                menu-dropdown-trigger
              >
                <svg-icon svgClass="icon" src="/assets/icons/icon-user.svg"></svg-icon>
                <span content>
                  <ng-container *ngTemplateOutlet="userAccount"></ng-container>
                </span>
              </app-menu-icon-link>
              <div *ngIf="userMenuLinks.length" menu-dropdown-section>
                <a *ngFor="let link of userMenuLinks" [routerLink]="link.url | localize" menu-dropdown-item>{{
                  link.name | translate
                }}</a>
              </div>
              <div class="desktop-only" menu-dropdown-section>
                <button (click)="logout()" [facet]="'danger'" menu-dropdown-item>
                  {{ 'shared.menu.logout' | translate }}
                  <span menu-dropdown-icon-right>
                    <svg-icon
                      [svgStyle]="{ 'width.px': 14, 'height.px': 12 }"
                      src="/assets/icons/arrow-right.svg"
                    ></svg-icon>
                  </span>
                </button>
              </div>
            </app-menu-dropdown>
          </ng-container>
          <ng-template #simpleUserMenuLink>
            <app-menu-icon-link class="mobile-only pointer-events-none" [tooltip]="'user-account' | translate">
              <svg-icon svgClass="icon" src="/assets/icons/icon-user.svg"></svg-icon>
              <span content>
                <ng-container *ngTemplateOutlet="userAccount"></ng-container>
              </span>
            </app-menu-icon-link>
            <app-menu-dropdown class="desktop-only">
              <app-menu-icon-link
                [tooltip]="'user-account' | translate"
                [withChevronMobile]="true"
                [activePaths]="profileActivePaths"
                menu-dropdown-trigger
              >
                <svg-icon svgClass="icon" src="/assets/icons/icon-user.svg"></svg-icon>
                <span content>
                  <ng-container *ngTemplateOutlet="userAccount"></ng-container>
                </span>
              </app-menu-icon-link>
              <button (click)="logout()" [facet]="'danger'" menu-dropdown-item>
                {{ 'shared.menu.logout' | translate }}
                <span menu-dropdown-icon-right>
                  <svg-icon
                    [svgStyle]="{ 'width.px': 14, 'height.px': 12 }"
                    src="/assets/icons/arrow-right.svg"
                  ></svg-icon>
                </span>
              </button>
            </app-menu-dropdown>
          </ng-template>
          <ng-template #userAccount>
            <ng-container *ngIf="(userInfo$ | async)?.firstName">
              {{ (userInfo$ | async)?.firstName }} {{ (userInfo$ | async)?.lastName }}
            </ng-container>
            <ng-container *ngIf="!(userInfo$ | async)?.firstName">
              {{ (userInfo$ | async)?.email }}
            </ng-container>
          </ng-template>
        </li>
      </ul>
      <div class="menu">
        <app-action-button
          class="mobile-only"
          [label]="'shared.menu.logout' | translate"
          [isFullWidth]="true"
          (action)="logout()"
          [facet]="'secondary-danger'"
          [size]="'small'"
        ></app-action-button>

        <ng-container *ngTemplateOutlet="accountSettings"></ng-container>
      </div>
    </div>
  </ng-template>

  <ng-template #publicSettings>
    <div class="flex items-center justify-between">
      <span class="inline lg:hidden font-bold">{{ 'shared.menu.language' | translate }}</span>
      <app-language-switch [type]="languageSwitchTypeEnum.menuMobile"></app-language-switch>
    </div>
    <div class="flex items-center justify-between">
      <span class="inline lg:hidden font-bold">{{ 'shared.menu.appearance' | translate }}</span>
      <app-appearance-switcher />
    </div>
  </ng-template>

  <ng-template #accountSettings>
    <div class="flex items-center justify-between">
      <span class="inline lg:hidden font-bold">{{ 'shared.menu.appearance' | translate }}</span>
      <app-appearance-switcher />
    </div>
  </ng-template>

  <ng-template #burger>
    <div class="hamburger">
      <button class="text-grey w-[29px] h-[25px] relative" (click)="toggleMenu()">
        <span class="sr-only">Open main menu</span>
        <div class="block w-6 absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <span
            aria-hidden="true"
            class="block absolute h-[2px] w-[20px] bg-cm-blue-500 transform transition duration-500 ease-in-out"
            [ngClass]="{ 'rotate-45': !isClosed, ' -translate-y-2': isClosed }"
          ></span>
          <span
            aria-hidden="true"
            class="block absolute h-[2px] w-[20px] bg-cm-blue-500 transform transition duration-500 ease-in-out"
            [ngClass]="{ 'opacity-0': !isClosed }"
          ></span>
          <span
            aria-hidden="true"
            class="block absolute h-[2px] w-[20px] bg-cm-blue-500 transform transition duration-500 ease-in-out"
            [ngClass]="{ '-rotate-45': !isClosed, '!w-[20px]': !isClosed, ' translate-y-2': isClosed }"
          ></span>
        </div>
      </button>
    </div>
  </ng-template>
</header>
